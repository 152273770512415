@import url("https://fonts.googleapis.com/css2?family=Dosis:wght@400;500;600&family=Montserrat:wght@400;500&family=Noto+Sans+SC:wght@400;500;700&family=Patrick+Hand&family=Roboto+Condensed:wght@300;400;700&family=Shantell+Sans:wght@500;600;700&family=Ubuntu:wght@400;500&family=Ysabeau:ital,wght@0,100;0,200;0,300;0,500;1,1;1,100&display=swap");

/* font family rules
font-family: 'Dosis', sans-serif;
font-family: 'Montserrat', sans-serif;
font-family: 'Noto Sans SC', sans-serif;
font-family: 'Patrick Hand', cursive;
font-family: 'Shantell Sans', cursive;
font-family: 'Ysabeau', sans-serif; */

/* font-family: 'Ubuntu', sans-serif;
font-family: 'Roboto Condensed', sans-serif;
 */


/* color variables */
:root {
  /* main scheme */
  --federal-blue: #181356;
  --red-violet: #c23088;
  --plum: #f397d6;
  --lavender-pink: #f5badb;
  --second-pink: #f978c1;
  --baby-powder: #fffffa;

  /* alternates & asides */
  --mint-green: #c7eae4;
  --space-cadet: #2a2e45;
  --charcoal: #424956;
  --cool-grey: #9a94bc;
  --sunglow: #ffca3a;
  --vivid-sky-blue: #23c9ff;
  --honeydew: #ecfee8;
  --russian-violet: #231942;

  /* new colors */

  --lighter-than-light-pink: #ffe5ec;
  --light-light-pink: #ffc2d1;
  --med-pink: #ffb3c6;
  --crystal-pink: ffc6ff;
  --med-red-pink: #ef476f;
  --light-light-purple: #ffd6ff;
  --med-purple: #c8b6ff;
  --crystal-purple: #bdb2ff;
  --fun-purple: #9d4edd;
  --light-light-blue: #caf0f8;
  --crystaly-blue: #b5e2fa;
  --crystal-blue-green: #9bf6ff;
  --light-bright-blue: #48cae4;
  --med-bright-blue: #3a86ff;
  --med-font-blue: #3f587f;

  --light-light-green: #c7f9cc;
  --crystaly-green: #72efdd;
  --light-bright-green: #80ed99;
  --med-green: #06d6a0;
  --light-sand-gold: #ffe6a7;
  --crystal-yellow: #fdffb6;
  --med-gold: #ffd166;
  --dark-gold: #fca311;

  --test-purple: #7209b7;
  --med-red: #d90429;
  --fun-orange: #ff6700;
  --med-bright-orange: #9824f8;
  --med-bright-green: #38b000;
  --darker-bright-purple: #5603ad;

  --bark-pink: #fd72b1;
  --test-blue: #f9fafc;
  --test-blue: #eff3fc;
  --test-red: #f80282;
  --test-light-green: #eefef4;
  --test-light-pink: rgb(252, 221, 239);

  --grey-60: rgb(60, 60, 60);
  --grey-80: rgb(80, 80, 80);
  --grey-100: rgb(100, 100, 100);
  --grey-180: rgb(180, 180, 180);
  --grey-220: rgb(220, 220, 220);
  --grey-240: rgb(240, 240, 240);
  --grey-248: rgb(248, 248, 248);

  --llc-blue:  #061d7d;

  /* fonts */
   --font-main-rc:font-family: "Roboto Condensed", sans-serif;

  /* box-shadows */
  --shadow-main-light:  3px 0.5px 6px rgb(180, 180, 180);

  /* transitions */
  --transition-main:all 0.3s ease;
  --transform-main-scale: scale(1.1);
}

* {
  margin: 0;
  transition: all 0.05s ease-in;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* font-family: "Signika Negative", sans-serif; */
  font-family: "Roboto", sans-serif;
  position: relative;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
input {
  outline: none;
}
select {
  outline: none;
}
.alert {
  background: white;
  z-index: 20;
  padding: 1rem 3rem;
  padding: 2rem 3rem;
  border-radius: 1rem;
  box-shadow: 5px 2px 5px rgb(80, 80, 80);
  border: 1px solid var(--test-blue);
  max-width: 250px;
  color: rgb(80, 80, 80);
  color: var(--federal-blue);
  color: var(--space-cadet);
  color: rgb(150,150,150);
  /* font-weight: bold; */
  font-weight: 600;
  z-index: 20;
}
.alert-container {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20;
  box-sizing: border-box;
  
}
.alert-container-second {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  /* align-items: center; */
  z-index: 20;
  box-sizing: border-box;
}
.beat-loader {
  width: min-content;
}

.btn {
  border: none;
  font-weight: bold;
  padding: 1rem;
  border-radius: 2rem;
  color: black;
}
.btn:hover {
  cursor: pointer;
}
.btn-tip {
    display: flex;
    align-items: center;
    gap: 0.4rem;
    background: white;
    border: 1px solid var(--test-blue);
    box-shadow: 5px 2px 5px rgb(80, 80, 80);
    letter-spacing: 0.02rem;
    padding: 1rem 2rem;
    margin-top: 0.6rem;
}

.dash-page-name {
  height: 1.35rem;
  width: min-content;
  border-radius: 2rem;
  padding: 1rem 6vw;
  background: rgb(244, 244, 244);
  font-size: 1.2rem;
  letter-spacing: 0.1rem;
}
.dash-header {
  width: 100%;
  display: flex;
  align-items: center;
  align-items: baseline;
  justify-content: space-between;
  padding: 0.6rem 1rem;
  box-sizing: border-box;
}
.dash-main {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.filter {
  font-size: 0.8rem;
  border-radius: 0.4rem;
  border-radius: 2rem;
  border: 2px solid rgb(230, 230, 230);
  /* border: 2px solid var(--test-blue); */
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.2rem;
  color: rgb(20, 20, 20);
  align-self: flex-start;
  padding: 0.5rem 0.8rem;
  margin: 0.6rem 1.7rem;
  margin-right: 0;
  position: relative;
}
.filter:hover {
  cursor: pointer;
}
.filters-btn {
  background: white;
  border: 2px solid var(--test-blue);
  padding: 0.2rem 0.4rem;
  border-radius: 0.5rem;
  color: black;
}
.filters-btn:hover {
  cursor: pointer;
}
.filters-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.4rem;
  padding-bottom: 0.4rem;
  border-bottom: 1px solid rgb(230, 230, 230);
}
.filters-menu-main {
  width: min-content;
  background: white;
  position: absolute;
  top: 12.5rem;
  right: 2.6rem;
  top: 8rem;
  z-index: 2;
  border-radius: 0.4rem;
  border: 1px solid rgb(230, 230, 230);
  box-shadow: 5px 2px 5px grey;
  box-shadow: 5px 2px 5px rgb(80, 80, 80);
  padding: 0.6rem;
  font-size: 0.8rem;
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  z-index: 6;
}
.filter-option {
  font-size: 0.8rem;
  border-radius: 0.4rem;
  border: 1px solid rgb(230, 230, 230);
  background: white;
  color: rgb(80, 80, 80);
}
  .form {
    background: white;
    font-family: "Roboto", sans-serif;
    border-radius: 1rem;
    box-sizing: border-box;
    display: grid;
    place-items: center;
  }
   .form-checkbox-container {
    margin-bottom: 1rem;
  }
  .form-checkbox-label {
    font-size: 0.8rem;
    color: rgb(120, 120, 120);
  }
  .form-checkbox-row {
    display: flex;
    gap: 0.6rem;
    margin: 0.3rem 0;
  }
  .form-header {
    width: 100%;
    max-width: 330px;
    margin: 0.4rem 0;
    font-weight: 500;
    font-size: 1.4rem;
    color: black;
    font-weight: bold;
    background: rgb(248, 248, 248);
    padding: 0.6rem 1rem;
    box-sizing: border-box;
  }
.form-input {
  border-radius: 1.5rem;
  height: 2rem;
  border: 2px solid var(--grey-240);
   border: 2px solid var(--test-blue);
  padding-left: 0.8rem;
  background: none;
  background-color: none;
  font-size: 16px;
  display: flex;
  align-items: center;
}
.form-input-address {
  width: 94%;
  border: 2px solid var(--test-blue);
  /* border: 2px solid var(--federal-blue); */
}
.form-input-image {
  display: none;
}
.form-label {
  font-weight: bold;
  font-size: 0.8rem;
  color: var(--grey-100);
}
.form-label-image {
  border-radius: 1.5rem;
  height: 2rem;
  border: 2px solid var(--grey-240);
  padding-left: 0.8rem;
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.4rem;
}
.form-label-image:hover {
  cursor: pointer;
}
.form-main {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    box-sizing: border-box;
  }
.form-row {
  width: 20rem;
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  margin-bottom: 1.2rem;
  position: relative;
}

.form-input::placeholder {
  color: rgb(180, 180, 180);
}
.form-select {
    width: 90%;
     border: none;
  }
.form-select:focus {
   outline: none;
  }
.form-textarea {
  border-radius: 1.5rem;
  border: 2px solid var(--grey-240);
  border: 2px solid var(--test-blue);
  padding-left: 0.8rem;
  padding-top: 0.6rem;
  background: none;
  resize: none;
  outline: none;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
}
.form-textarea::placeholder {
    color: rgb(180, 180, 180);
    font-size: 16px;
}

.full-page {
  height: 100vh;
  width: 100vw;
}

.hidden {
  position: absolute;
  right: 0;
  top: 30rem;
}
.icon-paw {
  margin: 0 0.4rem;
}
.icon-venmo {
  color: var(--med-bright-blue);
}
.link {
  text-decoration: none;
  color: black;
}
.llc {
  color: rgb(180,180,180);
  color: var(--bark-pink);
  font-size: 0.8rem;
}
.llc-link {
  color: var(--test-blue);
  color: var(--llc-blue)
}
.no-content {
  background: white;
  width: 100vw;
  height: 40vw;
  display: grid;
  place-items: center;
  font-weight: bold;
  letter-spacing: 0.02rem;
  text-align: center;
  color: rgb(80, 80, 80);
  padding: 0 2rem;
  box-sizing: border-box;
}
.outlet-container {
  width: 100vw;
  height: min-content;
  min-height: 90vh;
  min-height: 70vh;
  padding-bottom: 2rem;
}
.post-single-icon-back {
  margin: 1rem;
  margin-bottom: 0;
}
.profile-input-image {
  max-width: 200px;
  margin-bottom: 1rem;
}
.register-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.6rem;
  padding-top: 1rem;
}
.sample-user-name {
  font-size: 1rem;
  font-weight: 400;
  color: var(--bark-pink)
}
.scrolled-nav {
  position: fixed;
  top: 0;
}
.scrolled-task-bar {
  position: fixed;
  top: 4rem;
}
.test {
  border: 5px solid red;
}
.sticky-nav {
  position: sticky;
  position: fixed;
  top: 0;
  z-index: 7;
}
.date-picker {
  border: 1px solid rgb(220, 220, 220);
  border-radius: 0.5rem;
  text-align: center;
  padding: 0.2rem 0;
  font-size: 16px;
  color: rgb(100, 100, 100);
  color: var(--federal-blue);
  width: 30vw;
  background: white;
}
/* .post-comment-row > ::placeholder {
  color: black;
  opacity: 1;
} */

select:required:invalid {
  color: rgb(160, 160, 160);
}
.overflow-hidden {
    overflow-x: hidden;
    position: fixed;
  }

@media (max-width: 400px) {
  .alert {
  
    
  }
}
